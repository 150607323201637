import React from 'react'
import { Link, navigate } from 'gatsby'
import PurchaseFormConfirm from '../components/Forms/purchaseFormConfirm'
import BooksInCartConfirm from '../components/booksInCartConfirm'
import Seo from "../components/seo"
import { sessionKeys } from "../services/const"
import { getFormValues, postForm } from '../services/form'

class OrderConfirm extends React.Component {

  handleSubmit = event => {
    event.preventDefault()
    const postData = getFormValues(sessionKeys.orderForm)
    if (postForm(process.env.API_GATEWAY_BASE_URL + "order", postData)) {
      navigate('/orderComplete')
    }
    else {
      console.log('Server Error');
    }
  }

  handleBack = () => {
    navigate(-1)
  }

  render = () => {
    const orderForm = getFormValues(sessionKeys.orderForm)

    return (
      <>
        <Seo title="出版・刊行物 ご注文手続き" />
        <div id="pageTitle">
          <h1>
            出版・刊行物 ご注文手続き
          </h1>
        </div>
        <div className="breadClumb">
          <ul>
            <li><Link to="/">HOME</Link></li>
            <li><Link to="/publication">出版・刊行物</Link></li>
            <li>出版・刊行物 ご注文手続き</li>
          </ul>
        </div>


        <div id="main">
          <div className="contents">
            <form name="" onSubmit={this.handleSubmit}>
              <BooksInCartConfirm></BooksInCartConfirm>
              <PurchaseFormConfirm orderForm={orderForm}></PurchaseFormConfirm>
              <div className="aC"><input type="submit" value="この内容で注文する" className="btn_rounded" /></div>
              <div className="aC mb50"><input type="button" value="戻る" className="btn_rounded keyColor08b" onClick={this.handleBack} /></div>
            </form>
          </div>
        </div>
      </>
    )

  }
}

export default OrderConfirm;