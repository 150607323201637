import React from 'react'
import { getValue } from '../../utils/check'

const PurchaseFormConfirm = ({ orderForm }) => {

  return (
    <>
      <h2 className="header01">送付先の情報</h2>
      <section className="contents_block narrow mb50">
        <div className="form_block">
          <div className="form_head">お名前</div>
          <div className="form_body">
            {orderForm.LastNameKanji} {orderForm.FirstNameKanji}
          </div>
        </div>

        <div className="form_block">
          <div className="form_head">お名前（カナ）</div>
          <div className="form_body">
            {orderForm.LastNameKatakana} {orderForm.FirstNameKatakana}
          </div>
        </div>

        <div className="form_block">
          <div className="form_head">メールアドレス</div>
          <div className="form_body">
            {orderForm.MailAddress}
          </div>
        </div>

        <div className="form_block">
          <div className="form_head">書籍の送付先</div>
          <div className="form_body">
            {orderForm.SendingAddress}
          </div>
        </div>

        <div className="form_block">
          <div className="form_head">会社名</div>
          <div className="form_body">
            {orderForm.CompanyName}
          </div>
        </div>

        <div className="form_block">
          <div className="form_head">郵便番号</div>
          <div className="form_body">
            {orderForm.PostalCode}
          </div>
        </div>

        <div className="form_block">
          <div className="form_head">
            ご住所
          </div>
          <div className="form_body">
            {orderForm.Prefectural} {orderForm.Municipalities} {orderForm.Address}
          </div>
        </div>

        <div className="form_block">
          <div className="form_head">
            電話番号
          </div>
          <div className="form_body">
            {orderForm.TelephoneNumber}
          </div>
        </div>

        <div className="form_block">
          <div className="form_head">
            FAX番号
          </div>
          <div className="form_body">
            {orderForm.FaxNumber}
          </div>
        </div>

        <div className="form_block">
          <div className="form_head">通信欄</div>
          <div className="form_body">
            {orderForm.Message}
          </div>
        </div>

        <div className="form_block">
          <div className="form_head">支払方法</div>
          <div className="form_body">
            {orderForm.PaymentType}
          </div>
        </div>

        <div className="form_block">
          <div className="form_head">ご返信先</div>
          <div className="form_body">
            {orderForm.RepliesAddress}
          </div>
        </div>

        <div className="form_block">
          <div className="form_head">必要書類</div>
          <div className="form_body">
            {getValue(orderForm.Documents, []).join("　")}
          </div>
          <div className="form_head"></div>
          <div className="form_body">
            宛名：{orderForm.NameAndAddress}
          </div>
        </div>

      </section>
    </>
  )
}

export default PurchaseFormConfirm