import React from 'react'
import { getCart } from '../services/cart'
import { isLoggedIn } from '../services/auth'

const BooksInCartConfirm = () => {
  const books = getCart();
  const bookElements = books.map(book => {
    const { title, publicUrl, memberprice, price, count } = book

    return (
      <li key={book.id}>
        <div className="book_img"><img src={publicUrl} alt="" className="imgFit" /></div>
        <div className="book_info">
          <div className="book_title">
            {title}
          </div>
          <div className="book_price">
            価格：{isLoggedIn() ?
              String(memberprice).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,') :
              String(price).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')}円（税込）
          </div>
          <div className="book_qty">
            お申込み冊数&nbsp;
            {count}冊
          </div>
        </div>
      </li>
    )
  })

  return (
    <section className="contents_block">
      <h2 className="header01">ご注文内容の確認</h2>
      <div className="inner">
        <p className="mb50">お申し込み内容をご確認いただき、「この内容で注文する」ボタンをクリックしてください。</p>
        <ul className="book_cartList">
          {bookElements}
        </ul>
      </div>
    </section>
  )
}

export default BooksInCartConfirm